/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "./assets/styles/fontawesome-free-5.15.3-web/css/all.min.css";

html {
  font-family: Arial, Helvetica, sans-serif;
  color: lightgray;
}

i::content {
  font-family: Arial, Helvetica, sans-serif;
}

i {
  color: #fe90de;
}

button {
  margin: 10px;
  border-radius: 23px;
  padding: 5px;
  width: 40px;
  height: 40px;
  outline: 0 !important;
}

#search {
  margin: 33px auto 23px auto;
  width: calc(100% - 25px);
  height: 50px;
  font-size: x-large;
  border-radius: 23px;
  outline: 0 !important;
}

// li,
// .app-point-of-interest-details {
//   border-radius: 3px;
//   color: gray;
//   background-color: whitesmoke;
//   margin: 23px 0;
//   border: 4px solid #65fbfb;
// }

ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: larger;
}

html,
body {
  background-color: #333333;
  color: lightgray;
}

button,
button:focus {
  border: 1px solid #333333;
  background-color: #fe90de;
  color: lightgrey;
}

button i,
button:focus i {
  color: #333333;
}

li:hover,
button:hover {
  color: #333333;
  background-color: lightcyan;
}

.row img {
  float: left;
}

li:active,
button:active {
  color: #65fbfb;
  background-color: lightcyan;
  border-color: lightcyan;
}

li:active i,
button:active i {
  color: #65fbfb;
}

h4 {
  font-size: 1rem;
  line-height: normal;
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  margin: auto;
  z-index: 99999;
  display: block;
  width: 100vw;
  height: 100vh;
}

#video {
  position: fixed;
  text-align: center;
  margin: auto;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100vw;
  z-index: 0;
  display: block;
  opacity: 0.8;
}

#spinner {
  background-image: url("./assets/icon.gif");
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  text-align: center;
  margin: auto;
  height: 200px;
  width: 200px;
  z-index: 99999;
}

#img {
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100vh;
  text-align: center;
  position: absolute;
  margin: auto;
  z-index: 99999;
  opacity: 0.9;
}

#features {
  margin: auto;
  text-align: center;
}

.p-0 {
  padding: 0 !important;
}

#impressum,
#impressum:hover {
  text-align: center;
  margin: auto;
  background-color: whitesmoke;
  color: whitesmoke;
}

#impressum ul,
#impressum:hover ul {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

#impressum ul li,
#impressum:hover ul li {
  border: 0 !important;
  width: 100%;
  height: 50px;
  margin: 11px 0;
}

#impressum li:hover,
#impressum:hover li:hover {
  background-color: whitesmoke;
}

#impressum li:hover a:hover,
#impressum:hover li:hover a:hover {
  color: lightcyan;
}

#impressum div,
#impressum:hover div {
  border-radius: 23px;
}

#impressum li,
#impressum:hover li {
  width: 50vw;
}

#imprint {
  text-align: center;
  position: relative;
}

#imprint-main {
  text-align: center;
  position: fixed;
}

iframe.video {
  height: 100vh;
  width: 100vw;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: -7px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: lightcyan;
}

input:focus+.slider {
  box-shadow: 0 0 1px lightcyan;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.running {
  color: #fe90de !important;
  background-color: #65fbfb !important;
  border-color: lightcyan !important;
}

.running i {
  color: #fe90de !important;
}

.button {
  width: auto;
  padding: 0 10px;
  height: 30px;
}

#imprint-main.lastbottom {
  position: fixed !important;
  margin: auto;
  width: 100%;
  top: 90vh;
  z-index: 1000000000010000000000;
  font-weight: bold;
  background-color: transparent; //whitesmoke;
  height: 100%;
}

#imprint-main.bottom {
  position: fixed;
  margin: auto;
  width: 100%;
  top: calc(100vh - 150px);
  z-index: 10000000000;
  font-weight: bold;
  background-color: whitesmoke;
  height: 100%;
}

.scroll-content {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden !important;
}

app-point-of-interest-list {
  overflow: hidden;
  height: 70vh;
}

app-root,
body,
html {
  height: 110vh;
  overflow: hidden;
}

#behind {
  z-index: 0;
}

#in-front {
  z-index: 1;
}

.center {
  top: 0px;
  width: 100vw;
  height: 100vh;
  padding: 5%;
  margin: auto;
}

.icon-loader {
  width: auto;
  height: 50vh;
  background-image: url("./assets/ic_launcher.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  margin: auto;
}

.width-button {
  position: relative;
  text-align: center;
  width: 30vh;
  color: blue;
  font-size: 1.5vh;
  margin: auto;
  padding-bottom: 4.5vh;
}



app-face {
  margin: auto;
  text-align: center;
  z-index: 999;
}

#face {
  width: 100px;
  height: auto;
  margin: auto;
  background-position: center;
}
.face-bg {
  width: 150px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./assets/face/default.jpeg");
  z-index: -99;
}
#face img {
  position: fixed;
}

ins {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
 }
